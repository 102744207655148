import React, { useEffect, useState } from 'react'
import logo from '../img/logo.png'
import './ComingSoon.css'
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";

export const ComingSoon = () => {
    //Remove padding from main when on smaller device
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

    useEffect(() => {
        window.addEventListener("resize", () => {
            const mobileCheck = window.innerWidth < 850;

            if (mobileCheck !== isMobile) setIsMobile(isMobile);
        }, false);
    }, [isMobile]);

    //Mailchimp URL
    const url = "https://alcidormedia.us16.list-manage.com/subscribe/post?u=38367781d2ab6cf3cd223913a&amp;id=38763bc885&amp;f_id=00877fe0f0";
    //Mailchiimp form functions
    const { loading, error, success, message, handleSubmit } = useMailChimpForm(url);
    //Fields to set
    const { fields, handleFieldChange } = useFormFields({
        EMAIL: "",
    });

    return (
        <div className='container-fluid'>
            <header className='container'>
                {/* Navbar */}
                <nav className='pt-3'>
                    <div>
                        <a className='navbar-brand' href='#!'>
                            <img className='me-2' src={logo} height={40} logo="lazy" alt='Alcidor Tech Logo' />
                        </a>
                    </div>
                </nav>
            </header>

            <main className={`${isMobile ? "p-0" : "px-5"}`}>
                {/* Jumbotron */}
                <section id='Jumbotron' className='text-center text-white my-3'>
                    <div className='d-flex align-items-center flex-column'>
                        <div className="p-2 m-2">
                            <h1 className={`${isMobile ? "fs-60-mobile" : "fs-60"}`}>Create And Manage Your Web Applications With Ease</h1>
                        </div>

                        <div className="p-2 m-2">
                            <p className='my-3 fs-5'>Alcidor Tech Offers Services For Businesses To Create, Manage, And Maintain Web And Mobile Applications.
                                <span className='d-none d-lg-block'>Get The Most Out Of Your Business Applications With Alcidor Tech.</span></p>
                        </div>

                        <div className="p-2 m-2">
                            <form className='d-flex input-group' onSubmit={event => {
                                //stop default form action of redirecting
                                event.preventDefault();
                                //pass fields to mailchimp form
                                handleSubmit(fields);
                            }}>
                                <input id='EMAIL' className={[`${isMobile ? "jumbo-input-mb " : 'jumbo-input '}`, ' form-control']} type='email' name='email' autoComplete='new-email'
                                    placeholder="Your email" required value={fields.EMAIL} onChange={handleFieldChange} />
                                <button type='submit' className='btn btn-primary submit-btn'>Join Waitlist</button>
                            </form>
                            <div className='fs-3 my-1 text-warning'>{loading && "submitting"}</div>
                            <div className='fs-3 my-1 text-danger'>{error && message}</div>
                            <div className='fs-3 my-1 text-success'>{success && message}</div>
                        </div>
                    </div>
                </section>

                {/* Services */}
                <section className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='p-4'>
                                <h6 className='mb-0'>Why Alcidor Tech?</h6>
                                <hr className='mt-1' />
                                <h3>Streamline Your Web And Mobile Application Processes</h3>
                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className="row row-cols-1 row-cols-md-3 g-4">
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h5 className="card-title pb-3"><i className="fa-solid fa-desktop me-2 text-primary"></i> Website Design</h5>
                                            <p className="card-text">Create a website that looks professional and is up-to-date with Alcidor Tech's website design services</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h5 className="card-title pb-3"><i className="fa-solid fa-mobile me-2 text-primary"></i> App Development</h5>
                                            <p className="card-text">Create custom mobile and web applications tailored to your business needs with Alcidor Tech's application development services</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h5 className="card-title pb-3"><i className="fa-solid fa-warehouse me-2 text-primary"></i> Domain Management</h5>
                                            <p className="card-text">Keep your applications secure and running smoothly with Alcidor Tech's app maintenance services</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Steps to contact */}
                <section id="SimpleSteps" className='container'>
                    <div className='row'>
                        <h2 className='text-center fw-bold fs-1 mb-4'>In 3 Simple Steps</h2>

                        <div className='row text-center'>
                            <div className='col-md mb-4'>
                                <i className="fa-solid fa-phone fa-4x mb-1 text-primary"></i>
                                <h3>Contact Alcidor Tech</h3>
                                <p>Contact us to discuss your project and how we can help.</p>
                            </div>
                            <div className='col-md mb-4'>
                                <i className="fa-solid fa-laptop-code fa-4x mb-1 text-primary"></i>
                                <h3>Design & Develop</h3>
                                <p>Our team will design and develop your web and mobile applications according to your needs.</p>
                            </div>
                            <div className='col-md mb-4'>
                                <i className="fa-solid fa-gears fa-4x mb-1 text-primary"></i>
                                <h3>Maintain & Update</h3>
                                <p>We will provide ongoing maintenance and updates to keep your applications secure and running smoothly.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Join waitlist */}
                <section className='container'>
                    <div className='row ps-md-0 pe-md-0 p-3' style={{ backgroundImage: 'linear-gradient(33deg, #0c1d37, #17215a)', color: 'white', borderRadius: 35 }}>
                        <div className='col-lg-6'>
                            <div className='p-4'>
                                <p className='m-0'>Ready to get started?</p>
                                <h2>Join the waitlist now!</h2>
                                <p>You will receive an email and a special offer when we launch!</p>
                                <div>
                                    <form className='row row-cols-lg-auto g-3 align-items-center'
                                        onSubmit={event => {
                                            //stop default form action of redirecting
                                            event.preventDefault();
                                            //pass fields to mailchimp form
                                            handleSubmit(fields);
                                        }}>
                                        <div className='col-md-6'>
                                            <input id="EMAIL" type="email" className='form-control cta-input' name="email" autoComplete='new-email' placeholder="Your email" value={fields.EMAIL} onChange={handleFieldChange} />
                                        </div>
                                        <div className='col-md-6'>
                                            <button type='submit' className='btn btn-primary btn-rounded btn-lg'>Join Waitlist</button>
                                        </div>
                                    </form>

                                    <div className='fs-3 my-1 text-warning'>{loading && "submitting"}</div>
                                    <div className='fs-3 my-1 text-danger'>{error && message}</div>
                                    <div className='fs-3 my-1 text-success'>{success && message}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
